@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'PP Editorial Old';
  src: url('fonts/PPEditorialOld-Italic.eot');
  src: url('fonts/PPEditorialOld-Italic.eot?#iefix') format('embedded-opentype'),
      url('fonts/PPEditorialOld-Italic.woff2') format('woff2'),
      url('fonts/PPEditorialOld-Italic.woff') format('woff'),
      url('fonts/PPEditorialOld-Italic.ttf') format('truetype'),
      url('fonts/PPEditorialOld-Italic.svg#PPEditorialOld-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PP Editorial Old';
  src: url('fonts/PPEditorialOld-Regular.eot');
  src: url('fonts/PPEditorialOld-Regular.eot?#iefix') format('embedded-opentype'),
      url('fonts/PPEditorialOld-Regular.woff2') format('woff2'),
      url('fonts/PPEditorialOld-Regular.woff') format('woff'),
      url('fonts/PPEditorialOld-Regular.ttf') format('truetype'),
      url('fonts/PPEditorialOld-Regular.svg#PPEditorialOld-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  @apply antialiased;
}

blockquote {
  @apply text-altblue italic text-base leading-normal md:text-xl font-bold pl-6 border-l-2 border-altblue;
}

.h1 {
  @apply text-blue font-sans font-semibold text-5xl leading-supertight md:text-7xl relative md:leading-none;
}

.h2 {
  @apply text-blue font-bold text-2xl md:text-4xl mb-4;
}

.svg-icon svg {
  @apply w-full h-full;
}

.navlink.active {
  @apply italic underline;
}
.navlink__mobile.active {
  @apply bg-blue text-green border-blue rounded hover:border-blue hover:bg-blue hover:text-green;
}

input[type="checkbox"]:checked + .checkmark__wrap .styled-checkbox {
  @apply border-green-active;
}
input[type="checkbox"]:checked + .checkmark__wrap .styled-checkbox .checkmark {
  @apply opacity-100;
}

button:disabled {
  @apply opacity-30;
}

.h1__underlined > span{
  @apply relative;
}
.h1__underlined > span:after {
  content: "";
  @apply block z-0 absolute top-8 -left-1 min1200px:-left-2.5 min1200px:top-12 w-full h-5.5 min1200px:h-8.5 bg-green green-bar;
}
.h1__underlined > span + span:after {
  @apply hidden min1200px:block min1200px:-left-[0.875rem] min1200px:w-[calc(100%+1.5rem)];
}
.h1__underlined > span em {
  @apply relative z-2 not-italic;
}

.content__two-col-mixed .content > p,
.content__two-col-mixed .column-2 p {
  @apply text-xs md:text-sm leading-5 md:leading-6 text-blue font-medium mb-4 md:mb-10;
}

.content__two-col-mixed .content blockquote,
.content__two-col-mixed .column-2 blockquote {
  @apply mb-4 md:mb-10;
}

.content__two-col-mixed .content blockquote p,
.content__two-col-mixed .column-2 blockquote p {
  @apply leading-normal text-altblue md:text-xl font-bold;
}

.content__mixed-copy .two-col p {
  @apply leading-5 mb-6 md:mb-9 md:leading-normal;
}

.content__left-img-with-txt p {
  @apply mb-6 md:mb-9 last:mb-0 leading-5 md:leading-normal;
}

.content__static-copy p {
  @apply mb-5;
}

/* .content__two-col-facts .right-col p,
.content__two-col-facts .right-col ul {
  @apply pb-6;
} */

.content__two-col-facts p + ul {
  @apply md:-mt-6;
}

.content__two-col-facts li {
  @apply pb-6;
}

.content__two-col-facts li:before {
  content: "\2022";
  @apply inline-block mr-1;
}

/* .content__two-col-facts .right-col blockquote p {
  @apply pb-0;
} */

.block__text-block .content > p {
  @apply text-white text-xs leading-5 md:text-sm font-medium md:leading-6 mb-9;
}

.block__text-block blockquote {
  @apply border-green;
}

.block__text-block blockquote p {
  @apply text-green leading-normal;
}

.content__two-col-cta .content__right-columm p {
  @apply text-xs leading-5 md:text-sm md:leading-normal font-medium mb-9;
}

.content__two-col-cta .content__right-columm blockquote p {
  @apply text-lg md:text-xl font-bold;
}

.green-bar {
  width: 105.729%;
}

.content__disclaimer p {
  @apply mb-4;
}

.content__disclaimer a,
.content__static-copy a {
  @apply underline hover:no-underline;
}

.dashed-line {
  @apply hidden md:block w-72 border-t-2 border-dashed border-green mt-10;
}

.dashed-line:last-child {
  @apply hidden;
}

.disclaimer--alt {
  background: #83f1aa !important;
  border-color: #83f1aa !important;
  color: #0f204c !important;
}

.disclaimer--alt:hover {
  background: #b9fdd1 !important;
  border-color: #b9fdd1 !important;
}

.disclaimer--alt:active {
  background: #43d18a !important;
  border-color: #43d18a !important;
}

.contact--alt {
  background: transparent !important;
  border-color: #fff !important;
  color: #fff !important;
}

.contact--alt:hover {
  background: #fff !important;
  color: #0f204c !important;
}

.nav--default:after {
  content: '';
  @apply block w-full h-0 border-b border-blue absolute left-0 -bottom-[1px];
}

.nav--alt:after {
  content: '';
  @apply block w-full h-0 border-b border-white absolute left-0 -bottom-[1px];
}

.video-bg:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  position: absolute;
  top: 0;
  left: 0;
}

.video-bg video {
  width: 100vw;
}

.newsletter-popup .mailchimp-form input {
  @apply border border-[#cbc2b5] font-bold text-blue;
}

.newsletter-popup .mailchimp-form input::placeholder {
  font-weight: 400 !important;
}

.mailchimp-form input:focus,
.newsletter-popup .mailchimp-form input:focus {
  outline: none;
  @apply border border-blue;
}

.mailchimp-form input.error,
.newsletter-popup .mailchimp-form input.error {
  @apply border-2 border-red;
}

.newsletter-popup .recaptcha-wrap {
  @apply pt-0 pb-4;
}

.bg-fullwidth {
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
}

.hero-section-two-col__desc ul {
  @apply list-disc pl-5;
}

@media (max-width: 767px) {
  .hero-section-fullwidth__col-wrap > p {
    margin-bottom: 12px;
  }
}
@media (min-width: 768px) {
  .hero-section-fullwidth__col-wrap > p {
    max-width: calc(50% - 40px);
  }
}
@media (min-width: 1024px) {
  .hero-section-fullwidth__col-wrap > p {
    max-width: 470px;
  }
  #component-67954b4317e1a {
    position: relative;
    left: 20px;
  }
}

@media (max-width: 1023px) {
  .content__hero-section-fullwidth br {
    display: none;
  }
}

@media (min-width: 1536px) {
  #component-6795486f240f1 > div {
    background-position: center -15vw;
  }
}

/* ANIMATIONS */
@media (min-width: 1025px) {
  .animate {
    @apply transition-all transform duration-700;
  }

  .slide-from-top {
    @apply opacity-0 -translate-y-12;
  }
  .slide-from-bottom {
    @apply opacity-0 translate-y-12;
  }
  .slide-from-left {
    @apply opacity-0 -translate-x-12;
  }
  .slide-from-right {
    @apply opacity-0 translate-x-12;
  }
  .fade-in-grow {
    @apply opacity-0 scale-90;
  }
  .fade-in {
    @apply opacity-0;
  }


  .on-screen .slide-from-top {
    @apply opacity-100 translate-y-0;
  }
  .on-screen .slide-from-bottom {
    @apply opacity-100 translate-y-0;
  }
  .on-screen .slide-from-left {
    @apply opacity-100 translate-x-0;
  }
  .on-screen .slide-from-right {
    @apply opacity-100 translate-x-0;
  }
  .on-screen .fade-in-grow {
    @apply opacity-100 scale-100;
  }
  .on-screen .fade-in {
    @apply opacity-100;
  }
}